/* eslint-disable */

export const environment = {
	production: true,
	PRODUCTION: true,
	supportedLanguages: ['en-US'],
	STRIPE: {
		KEY: '',
	},
	STRIPE_PUBLISH_KEY_INDIA: '',
	STRIPE_PUBLISH_KEY_FST: '',
	API_URL: 'https://dev-api.stubified.co',
	API_URL_FOR_UPLOAD_FILE: 'https://dev-api.stubified.co/google/upload?url=',
	REST_URL: 'https://rest.stubified.com',
	SOCKET: {
		URL: 'https://rest.stubified.com',
		RECONNECTION_ATTEMPTS: 3,
	},
	PAGINATION: {
		BILLING_INFO: 5,
		URLS: 25,
		DEFAULT: 25
	},
	HEAP_ID: '',
	FCM: {
		V_API_KEY: '',
		SERVER_KEY: ''
	},
};
